/* Stuff */
/* Orange heading */
/* Committee colors */
/* Classes which apply text color */
.fagkom {
  color: #a1d40a;
}
.bedkom {
  color: #36a;
}
.arrkom {
  color: #b70000;
}
.orange {
  color: #ee7810;
}
/* RANDOM SHIT (throw?) */
section#about .nav-pills li a {
  padding: 4px 10px;
  border-radius: 0;
}
section#articles img {
  width: 100%;
}
section#articles h3,
section#articles h4 {
  color: #666;
  text-align: left;
  word-wrap: break-word;
}
@media (max-width: 991px) {
  section#articles #article-frontpage-normal .article-widget-mobile-view-separator {
    clear: both;
  }
}
section#articles div:nth-of-type(3) {
  clear: left;
}
/* Business
-------------------------------------------------- */
section#business {
  /*.business-ingress {
        margin-bottom:20px;

        >p {
            .ingress;
        }
    }*/
  /*.contact-buttons {
        margin:0 auto;
    }

    .business-text {
        margin-bottom:30px;

        p {
            font-weight:400;
        }
    }

    h5 {
        color:#2fa3d3;
        font-weight:600;
    }

    h3 {
        font-weight:600;
        font-size:20px;
    }

    .expander {
        text-align:center;
    }

    .sale-box {
        text-align:left;
    }*/
}
section#business .sales-contact {
  background: white;
  color: #027bbb;
  font-size: 15px;
  line-height: normal;
  vertical-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
section#business .sales-contact i.glyphicon {
  color: #ee7810;
}
section#business .sale-box {
  background: #027bbb;
  padding-top: 10px;
  color: white;
  font-weight: 300;
  text-align: center;
}
section#business .sale-box .sale-heading {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 5px;
}
section#business .sale-box .sale-chevron {
  font-size: 18px;
}
section#business .sale-content {
  font-weight: 300;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  background: #d6eaff;
}
section#business .sale-content .sale-points {
  margin-top: 10px;
  padding-left: 20px;
}
section#business .sale-content span {
  color: #ee7810;
  padding-right: 5px;
}
section#business .sale-content li {
  padding-bottom: 10px;
}
/* Company
----------------------------- */
section#company .contactinfo {
  margin-top: 15px;
  background: #49a0cc;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
section#company .contactinfo p {
  font-weight: 300;
  font-size: 14px;
  color: white;
  display: inline;
}
section#company .contactinfo i,
section#company .contactinfo a {
  color: white;
  margin-bottom: 15px;
}
section#company .contactinfo i {
  margin-right: 10px;
}
